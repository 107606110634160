import React from 'react';
import { useStyletron } from '@activebrands/core-web/libs/styletron';

interface DefaultWrapperProps {
    $style?: any;
    children: React.ReactNode;
}

const DefaultWrapper = ({ $style, children }: DefaultWrapperProps) => {
    const [css] = useStyletron();

    return (
        <div
            className={css({
                aspectRatio: [
                    'var(--ratio-vertical-tertiary)',
                    null,
                    null,
                    null,
                    null,
                    'var(--ratio-vertical-primary)',
                ],
                ...$style,
            })}
        >
            {children}
        </div>
    );
};

export default DefaultWrapper;
